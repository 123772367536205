import party from 'party-js'

export function emoji(source: HTMLButtonElement, text: string) {
  const element = document.createElement('span')
  element.innerText = text
  party.scene.current.createEmitter({
    emissionOptions: {
      angle: party.variation.range(0, 360),
      bursts: [{ count: party.variation.range(10, 20), time: 0 }],
      initialColor: party.variation.gradientSample(
        party.Gradient.simple(
          party.Color.fromHex('#29aeff'),
          party.Color.fromHex('#ffffff')
        )
      ),
      initialLifetime: party.variation.range(1, 4),
      initialRotation: new party.Vector(0, 0, 90),
      initialSpeed: party.variation.range(50, 250),
      rate: 0,
      sourceSampler: party.sources.dynamicSource(source)
    },
    emitterOptions: {
      loops: 1,
      modules: [
        new party.ModuleBuilder()
          .drive('rotation')
          .by(t => new party.Vector(0, 0, 100).scale(t))
          .relative()
          .build(),
        new party.ModuleBuilder()
          .drive('opacity')
          .by(
            new party.NumericSpline(
              { time: 0, value: 1 },
              { time: 0.5, value: 1 },
              { time: 1, value: 0 }
            )
          )
          .through('relativeLifetime')
          .build()
      ],
      useGravity: false
    },
    rendererOptions: {
      applyLighting: undefined,
      shapeFactory: element
    }
  })
}

export function hearts(source: HTMLButtonElement) {
  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  path.setAttribute(
    'd',
    'M316.722,29.761c66.852,0,121.053,54.202,121.053,121.041c0,110.478-218.893,257.212-218.893,257.212S0,266.569,0,150.801 C0,67.584,54.202,29.761,121.041,29.761c40.262,0,75.827,19.745,97.841,49.976C240.899,49.506,276.47,29.761,316.722,29.761z'
  )
  const element = document.createElementNS('http://www.w3.org/2000/svg', 'svg') as unknown as HTMLElement
  element.setAttribute('viewBox', '0 0 512 512')
  element.setAttribute('height', '20')
  element.setAttribute('width', '20')
  element.appendChild(path)
  party.scene.current.createEmitter({
    emissionOptions: {
      angle: party.variation.range(0, 360),
      bursts: [{ count: party.variation.skew(20, 10), time: 0 }],
      initialColor: party.variation.gradientSample(
        party.Gradient.simple(party.Color.fromHex('#ffa68d'), party.Color.fromHex('#fd3a84'))
      ),
      initialSpeed: 400,
      rate: 0,
      sourceSampler: party.sources.dynamicSource(source)
    },
    emitterOptions: {
      loops: 1,
      modules: [
        new party.ModuleBuilder()
          .drive('size')
          .by(t => 0.5 + 0.3 * (Math.cos(t * 10) + 1))
          .build(),
        new party.ModuleBuilder()
          .drive('rotation')
          .by(t => new party.Vector(0, 0, 100).scale(t))
          .relative()
          .build()
      ],
      useGravity: false
    },
    rendererOptions: {
      applyLighting: undefined,
      shapeFactory: element
    }
  })
}
